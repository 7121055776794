import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.png'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <div href="#" className="image avatar"><img src={avatar} alt="David Correa" /></div>
                    <h1><strong>Hi, I'm David Correa</strong></h1>
                    <p>I'm a front-end developer that enjoys pixel-wrangling and building websites that are maintainable, functional and effective. My goal is to help my clients create responsive web applications and websites that look great across devices.</p>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
